.button-add {
    display: flex;
    align-items: center;

    &__button {
        width: 30px;
        height: 30px;
        border: 2px solid $primary-color;
        border-radius: $default-radius;
        display: flex;
        align-items: center;
        justify-content: center;

        &--disabled {
            border-color: $gray-3;
        }
    }

    &__label {
        color: $primary-color;
        font-family: $semi-bold;
        font-weight: 700;
        margin-left: 12px;
        font-size: 14px;

        &--disabled {
            color: $blue-disabled;
        }
    }
}

.p-button.p-button-icon-only {
    width: 30px;
    height: 30px;
    padding: 0.75rem 0;
}

.financial-content {
	position: relative;

	&__container {
		width: 100%;
		display: flex;
		flex-direction: column;
		max-width: 1119px;
		margin: 0 auto;
		&-box {
			width: 100%;
			max-width: 960px;
			margin: 3.75rem auto 0;
			padding: 0 1.5rem 0;
		}
		&-activities {
			width: 100%;
			max-width: 997px;
			margin: 3.75rem auto 0;
			padding: 0 1.5rem 0;
		}
	}

	&__title {
		color: $black;
		font-family: $semi-bold;
		margin: 0;
		line-height: normal;
	}

    &__subtitle {
        text-align: center;
        font-family: $regular;
        font-size: $h6-font-size;
    }

    &__cards {
            width: 100%;
            display: flex; 
            gap: 3.5rem;       
    }


    &__card {
        width: 100%;
        height: 100%;
        border-radius: 33px;
        padding: 50px 22px 128px;
        display: flex;
        flex-direction: column;
        max-width: 428px;
        gap: 24px;
        &-title {
            font-family: $semi-bold;
            font-size: $h5-font-size;
            color: $black;
            margin: 0;
            line-height: normal;
        }
        > div.ng-star-inserted {
            max-width: 307px;
            width: 100%;
            margin: 0 auto;
            .p-dropdown {
                border: 1px solid #6D6D82;
                border-radius: 8px;
                .p-inputtext {
                    color: #000;
                    &.p-placeholder {
                        color: #B2B2B2;
                    }
                }
            }
            .label-input {
                font-family: $regular;
                font-size: 0.875rem;
                color: $black;
                margin: 0 0 5px;
                display: block;
            }
        }
        &-button {
            margin-top: 1.25rem;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 14px;
            .p-button.p-button-icon-only {
                width: 30px;
                height: 30px;
                border: 2px solid #0050EC;
                color: #0050EC;
                &.p-disabled {
                    border: 2px solid $blue-disabled;
                    color: $blue-disabled;
                }
            }
            .button-add__label {
                color: $primary-color;
                font-family: $semi-bold;
                font-size: 0.875rem;
                margin: 0;
            }
        }
        &-title {
            twbk-tooltip {
                width: 16px;
                height: 16px;
                left: 0;
                top: -1px;
                position: relative;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
        }
        .content-tooltip {
            img {
                width: 16px;
                height: 16px;
            }
        }
        .trash-icon {
            cursor: pointer;
            width: 23px;
        }
    
        .button-add {
            &__label {
                color: $primary-color;
                font-family: $semi-bold;
            }
        }
        &-one {
            background-color: rgb(45 95 191 / 20%);
            .icon-deposit {
                width: 46px;
                height: 42px;
            }
        }
        &-two {
            background-color: rgb(29 197 125 / 20%);
            .icon-transfer {
                width: 41px;
                height: 42px;
            }
        }
    }    

    &__col {
        width: 100%;

        &.col {
            &-one {
                width: 100%;
                max-width: 443px;
                .col-one__title {
                    font-family: $medium;
                    color: #161723;
                    font-size: 0.875rem;
                }
            }
            &-two {
                width: 100%;
                max-width: 443px;
            }
        }
        .label-input {
            font-family: $medium;
            color: #161723;
            margin: 0 0 0.375rem 0;
            font-size: 0.875rem;
        }
    }

    &__selection {
        display: grid;
        grid-template-areas: ". . .";
        gap: 18px 0;
        margin-bottom: 37px;
        max-width: 336px;
        &--item {
            display: flex;
            gap: 12px;
            &-label {
                font-family: $regular;
                font-size: 1rem;
                color: #000000;
                margin: 0 0 0 0;
                line-height: normal;
            }
            .p {
                &-checkbox {
                    width: 18px;
                    height: 18px;
                    &-box {
                        width: 18px;
                        height: 18px;
                        border: 1px solid $primary-color;
                        border-radius: 0;
                        &.p-highlight {
                            background-color: $primary-color;
                        }                    
                    }
                }
            }
        }
    }
    
    &__fields {
        display: flex;
        flex-direction: column;
    }
    
    &__form {
        &--field {
            position: relative;
            twbk-tooltip.twbk-tooltip {
                position: relative;
                margin-left: 0.375rem;
                .content-tooltip {
                    width: 18px;
                    height: 18px;
                    top: 0;
                }
            }
            input,
			.p-calendar .p-inputtext,
			.p-dropdown,
			ngx-intl-tel-input input,
			.p-inputnumber input,
			.p-calendar input,
			.p-multiselect,
			.input-calendar input,
			.iti input,
			&-input {
				background-color: transparent;
				border: 1px solid #6D6D82;
				font-family: $regular;
				color: #161723;
				font-size: $h6-font-size;
				margin: 0;
				height: 60px;
				display: flex;
				align-items: center;
				border-radius: 8px;
                &.p-inputwrapper-filled {
                    height: auto;
                }
			}
        }
    }

	@media only screen and (min-width: 1025px) {
        padding: 3rem 0 10rem;
		&__container {
      	    padding: 0 1rem;
		}
        &__grid {
            display: grid;
            justify-content: center;
            grid-template-areas: ".";
            grid-template-columns: 50%;
            gap: 1rem;
        }

        &__fields {
            gap: 37px;
        }

        &__col {
            &.col {
                &-one {
                    .col-one__title {
                        margin: 0 0 25px 0;
                    }
                    .financial-content__fields {
                        gap: 21px;
                    }
                }
            }
        }
		&__title {
			font-size: $h1-font-size-big;
			text-align: center;
		}
	}
	@media (max-width: 1024px) {	
        margin: 2rem 0;
        &__col {
            &.col {
                &-one {
                    .col-one__title {
                        margin: 0 0 23px 0;
                    }
                    .financial-content__fields {
                        gap: 24px;
                        margin-bottom: 24px;
                    }
                }
            }
        }
        
        &__fields {
            gap: 24px;
        }
        
		&__container {
            padding: 0 1.5rem 0;
            &-box {
                margin: 1.75rem auto 0;
                padding: 0 1.5rem 11rem;
            }
            &-activities {
                padding: 0 1.5rem 11rem;
                margin: 1.5rem 0 0;
            }
		}
		&__title {
            font-size: $h1-font-size-small;
			text-align: left;
		}
        &__cards {
            flex-direction: column;
            gap: 1.5rem;       
        }
        
        &__card {
            padding: 14px 22px 26px;
            gap: 23px;
            &-title {
                font-family: $semi-bold;
                font-size: $h5-font-size;
                color: $black;
                margin: 0;
                line-height: normal;
            }
            > div.ng-star-inserted {
                max-width: 307px;
                width: 100%;
                margin: 0 auto;
                .p-dropdown {
                    border: 1px solid #6D6D82;
                    border-radius: 8px;
                }
                .label-input {
                    font-family: $regular;
                    font-size: 0.875rem;
                    color: $black;
                    margin: 0 0 5px;
                    display: block;
                }
            }
            &-button {
                margin-top: 1.25rem;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 14px;
                .button-add__label {
                    color: $primary-color;
                    font-family: $semi-bold;
                    font-size: 0.875rem;
                    margin: 0;
                }
            }
            .content-tooltip {
                img {
                    width: 16px;
                    height: 16px;
                }
            }
            .trash-icon {
                cursor: pointer;
                width: 23px;
            }
            
            .button-add {
                &__label {
                    color: $primary-color;
                    font-family: $semi-bold;
                }
            }
            &-one {
                background-color: rgb(45 95 191 / 20%);
                .icon-deposit {
                    width: 46px;
                }
            }
            &-two {
                background-color: rgb(29 197 125 / 20%);
                .icon-transfer {
                    width: 41px;
                }
            }
        }
        
	}
}