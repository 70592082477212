// ============================================================================
//
//    BASE - GENERAL
//
// ============================================================================

html,
body {
  height: 100%;

  &.dark-theme {
    --global-bg: #404258;
    --global-tx-color: #fff;
    --secondary-bg: #474e68;
    --secondary-tx-color: #f1f1f1;
    --btn-global-bg: #6b728e;
    --btn-tx-color: #fff;
    --btn-selected-bg: #414660;
  }
}

body {
  margin: 0;
  font-family: $regular;
  background-color: #fff;
  color: var(--global-tx-color);
}

.main {
  display: flex;
  flex-direction: row;
  width: $maxwidth;
  margin: $dp * 4 auto;

  &__table {
    width: 70%;

    &__button {
      width: 100%;
      display: flex;
      justify-content: flex-start;

      &:first-child {
        margin-right: $dp * 3;
      }
    }
  }

  &__modal  {
    width: 100%;
  }

  &__search {
    width: 30%;

    &__icons {
      display: flex;
      justify-content: flex-end;
      border-bottom: 1px solid $gray-4;

      button {
        background: $gray-4;
        color: $gray-3 !important;
        border-radius: 0;

        &:first-child {
          border-top-left-radius: 5px;
          border-right: 1px solid $gray-4;
        }

        &:last-child {
          border-top-right-radius: 5px;
        }

        &.active {
          background: $white;
          color: $gray-2 !important;
        }
      }
    }
  }
}

// Calendar primeng
.input-calendar {
  .p-button {
    background-color: $primary-color;
    border-color: $primary-color;
    width: 50px !important;
    height: 60px !important;
  }
}

.p-datepicker table td > span.p-highlight {
  background-color: $primary-color;
  color: $white;
}

.container {
  max-width: $max-container;
  margin: 0 auto;
  @media only screen and (min-width: 769px) {
    padding: 0 1rem;
	}
	@media (max-width: 768px) {
    padding: 0 24px;

	}
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}
