.welcome-content {
	background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 59px;
    width: 100%;
	@media only screen and (min-width: 1025px) {
		background-image: url('../../assets/images/layers/layer-welcome.svg');
		padding: 56px 0 196px;
	}
	@media (max-width: 1024px) {
		background-image: url('../../assets/images/layers/bg-sm-welcome.svg');
		background-position: 0 18px;
        padding: 18px 0 13rem;
		.grid {
			margin: 0;
		}
		&.content-2 {
			background-position: 0 48px;
		}
	}

	&__text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.text {
			&-title {
				text-align: center;
				color: $title-color-black;
				line-height: normal;
				font-family: $semi-bold;
				@media only screen and (min-width: 1025px) {
					font-size: 2.25rem;
					margin-bottom: 33px;
				}
				@media (max-width: 1024px) {
					font-size: 1.5rem;
					margin-bottom: 18px;
				}
			}
			&-subtitle {
				font-weight: 700;
				line-height: normal;
				text-align: center;
				color: initial;
				@media only screen and (min-width: 1025px) {
					font-size: 1.375rem;
				}
				@media (max-width: 1024px) {
					font-size: 1rem;
				}
			}
		}
	}

	&__card {
		background-color: $white-1;
		border-radius: 1.25rem;
		margin-bottom: 0;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		max-width: 1110px;
		@media only screen and (min-width: 1025px) {
			padding: 82px 95px 82px;
			margin-top: 40px;
			&.step-2 {
				padding: 49px 95px 46px;
				margin-top: 8px;
			}
		}
		@media (max-width: 1024px) {
			padding: 28px 31px 36px;
			margin-top: 43px;
			&.step-2 {
				padding: 32px 21px;
				margin-top: 0;
			}
		}

		.card-content__product {
			position: relative;
			&-title {
				margin: 0;
				font-family: $regular;
				font-size: 1.375rem;
				font-weight: normal;
				margin-bottom: 36px;
				@media (max-width: 500px) {
					font-size: 1rem;
					margin-bottom: 26px;
				}
			}

			&-subtitle {
				display: flex;
				align-items: center;
				margin-bottom: 1.375rem;
				margin-top: 40px;

				@media (max-width: 500px) {
					margin-bottom: 0.875rem;
				}

				.product-title {
					margin-left: 1rem;
					font-size: 1rem;
					font-family: $bold;
				}
			}

			&-list {
				list-style: initial; 
				padding-left: 30px;

				& > li {
					font-family: $regular;
					font-weight: 400;
					line-height: normal;
					margin-bottom: 14px;
					&:last-child {
						margin-bottom: 0;
					}
					@media only screen and (min-width: 1025px) {
						font-size: 1.25rem;
					}
					@media (max-width: 1024px) {		
						font-size: 0.875rem;
					}
					.tn {
						.big-font {
							font-family: $bold;
						}
					}
					.tb {
						font-family: $bold;
					}
					&::marker {
						font-size: 0.8em;
					}
				}
				@media (max-width: 1024px) {		
					padding-left: 14px;
				}
			}
		}

		.card-content__email {
			&-label {
				display: flex;
				align-items: center;
				@media only screen and (min-width: 1025px) {
					margin-bottom: 1.625rem;
				}
				@media (max-width: 1024px) {	
					margin-bottom: 1.5rem;
				}
				img {
					width: 100%;
					height: 100%;
					max-width: 29px;
					max-height: 29px;
				}
				.label-title {
					margin-left: 0.75rem;
					@media only screen and (min-width: 1025px) {
						font-size: 1.375rem;
					}
					@media (max-width: 1024px) {		
						font-size: 1.125rem;
					}
				}
			}
			&-form {
				.form-input {
					border-radius: 8px;
					background-color: $white;
					font-size: 1.125rem;
					color: #161723;
					height: 60px;
					padding: 1.125rem 1rem;
				}
				.field {
					position: relative;
				}
				@media only screen and (min-width: 1025px) {
					.field {
						margin-bottom: 26px;
					}
				}
				@media (max-width: 1024px) {		
					.field {
						margin-bottom: 30px;
					}
				}
			}

			&-info {
				.text {
					color: #374151;
					margin-bottom: .8em;
					@media only screen and (min-width: 1025px) {
						font-size: 1.25rem;
					}
					@media (max-width: 1024px) {	
						font-size: 1rem;
					}
				}
				.list {
					list-style: initial;
    				padding-left: 30px;
					li {
						color: #374151;
						margin-bottom: 0;
						&::marker {
							font-size: 0.8em;
						}
						@media only screen and (min-width: 1025px) {
							font-size: 1.25rem;
						}
						@media (max-width: 1024px) {	
							font-size: 1rem;
						}
					}
				}
			}

			.wrapp-cta {
				@media only screen and (min-width: 1025px) {
					margin-top: 48px;
					gap: 20px;
				}
				@media (max-width: 1024px) {	
					margin-top: 30px;
					gap: 8px;
				}
			}

			.p-button {
				width: 100%;
				height: 60px;
				justify-content: center;
				font-family: $medium;
				border-radius: 8px;
				font-size: 1rem;
				line-height: normal;
				&-success-welcome {
					background-color: #1dc57d;
					border-color: #1dc57d;
				}
				&-info-welcome {
					background-color: transparent;
					border-color: $primary-color;
					color: $primary-color;
				}
				@media only screen and (min-width: 1025px) {
					max-width: 540px;
				}
			}

			.p-button-success-welcome:focus {
				background: #1dc57d;
				color: #1f2d5e;
			}

		}

	}

	&__disclaimer {
		width: 100%;
		background-color: $white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 10px;
		.card-disclaimer__icon {
			margin-right: 0;
			width: 100%;
			height: 100%;
			max-width: 42px;
			max-height: 28px;
		}
		@media only screen and (min-width: 1025px) {
			margin: 2rem auto 0;
			padding: 1.5rem 1.5rem;
		}
		@media (max-width: 1024px) {	
			margin: 1.25rem auto 0;
			padding: 1.188rem 1.125rem;
		}
	}
}

.welcome-account {
	background-color: $white;
	padding-top: 40px;
    padding-bottom: 40px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: visible;
    box-shadow: 0px -2px 8px 0px #F1F1F1;
    height: 140px;
    z-index: 1;

	&::before {
		content: "";
		display: block;
		width: 100%;
		height: 130px;
		position: absolute;
		bottom: 100%;
		background: rgb(255 255 255 / .5);
		background: linear-gradient(to bottom, rgba(251, 251, 251, 0), #fbfbfb);
		pointer-events: none;
		transition: all 0.3s ease;
		opacity: 0;
	}	
	@media (max-width: 1024px) {
		padding-top: 40px;
		padding-bottom: 40px;
		&.wrapp-cta-otp {
			height: 205px;
		}
	}
	.grid {
		margin: 0;
	}
	.col-12 {
		padding: 0 0;
	}

	&__products {
	    display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		@media only screen and (min-width: 1025px) {
			gap: 20px;
		}
		@media (max-width: 1024px) {	
			gap: 8px;
		}

		.button-primary-welcome {
			width: 100%;
			height: 60px;
			background-color: $primary-color;
			border-color: $primary-color;
			justify-content: center;
			max-width: 418px;
			border-radius: 8px;
			font-size: 1rem;
			line-height: normal;
			font-family: $semi-bold;
			&:disabled {
				background: #e0e0e0;
				border: 2px solid #e0e0e0 !important;
				color: #ffffff;
				cursor: initial;
			}
			@media only screen and (min-width: 1025px) {
				max-width: 418px;
			}
			@media (max-width: 1024px) {
				max-width: 380px;
			}
		}

		@media (max-width: 1024px) {
			flex-direction: column;
			align-items: center;
		}

		.selectedButton, .unselectedButton {
			cursor: pointer;
			display: flex;
			align-items: center;
			border-radius: 999px;
			padding: 12px 112px 12px 12px;
			@media (max-width: 1024px) {
				padding: 12px 16px;
			}
		}

		.products-fields {
			@media (max-width: 1024px) {
				margin-bottom: 16px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.selectedButton {
			border: 2px solid $blue;
			background: var(--White, #FFF);
			.textButtonCheck {
				color: initial;
			}
		}

		.unselectedButton {
			border: 1px solid var(--Gray-300, #D0D5DD);
			background-color: $white;
		}

		.custom-radio {
			position: relative;
			width: 28px;
			height: 28px;
			-webkit-appearance: none;
			appearance: none;
			border: none;
			outline: none;
			cursor: pointer;
			margin: 0;
			border: 1px solid #c6c6c6;
			border-radius: 50%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			&:checked {
				background-image: url('/assets/svg/welcome/radioButton.svg');
			}
		}

		.textButtonCheck {
			font-size: 1rem;
			color: #667085;
			margin-left: 6px;
			@media (max-width: 500px) {
				font-size: 12px;
			}
		}

		.p-button {
			width: 100%;
			height: 60px;
			justify-content: center;
			font-family: $medium;
			border-radius: 8px;
			font-size: 1rem;
			line-height: normal;
			&-success-welcome {
				background-color: #1dc57d;
				border-color: #1dc57d;
			}
			&-info-welcome {
				background-color: transparent;
				border-color: $primary-color;
				color: $primary-color;
			}
			@media only screen and (min-width: 1025px) {
				max-width: 540px;
			}
		}

		.p-button-success-welcome:focus {
			background: #1dc57d;
			color: #1f2d5e;
		}
	}
}

::ng-deep .tn {
	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 20px !important;
} 
