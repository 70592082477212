// ============================================================================
//
//    BASE - SETTINGS (Variables)
//
// ============================================================================

:root {
	/* Palette */
	--white-color: #fff;
	--black-color: #000000;
	--gray-color-1: #424242;
	--gray-color-2: #b6b6b6;
	--gray-color-3: #e0e0e0;
	--gray-color-4: #f4f4f4;
	--green-color: #d4edc8;
	--red-color: #e30613;
	--yellow-color: #faf4c8;

	/* App Theme / Brand */

	--global-bg: #e0e0e0;
	--global-tx-color: #424242;

	--secondary-bg: #fff;
	--secondary-tx-color: #000000;

	--btn-global-bg: #dad8d8;
	--btn-selected-bg: #b6b6b6;
	--btn-tx-color: #000000;
}

// =============================================================================
// Default Radius, Sizes, Spaces, floats
// =============================================================================

$default-radius: 4px;
$default-padding: 10px;
$dp: $default-padding;
$maxwidth: 94%;
$breakpoint: 1024px;
$bp: $breakpoint;

$max-container: 90rem;

// =============================================================================
// Colors
// =============================================================================

// Generic Gray Colors
$black:  #000000;
$black-2:  #1C1D20;
$gray-1: #424242;
$gray-2: #b6b6b6;
$gray-3: #e0e0e0;
$gray-4: #f4f4f4;
$gray-5: #979797;
$gray-6: #333333;
$white:  #ffffff;
$white-1:#FBFBFB;

// Generic Colors
$red: #e30613;
$red-2: #f2c9c9;
$green: #1dc57d;
$blue: #2D5FBF;
$yellow: #faf4c8;
$base-color: #1f2d5e;
$primary-color: #0050ec;
$secondary-color: #1dc57d;
$blue2: #2d5fbf;
$blue-disabled: #8FB5FF;
// Status Colors
$pending-color: $gray-3;
$success-color: $green;
$warning-color: $yellow;
$danger-color: $red-2;

// Table Colors
$title-color: $gray-2;
$hover-color: $gray-4;

$title-color-black: $black-2;
// Title colors

$title-color-blue: $blue2;

// Inputs Colors
$input-hover-color: rgba($black, 0.12);
$input-focus-color: $primary-color;

// Button Colors
$primary-button-bg: $primary-color;
$primary-button-border: $primary-color;
$primary-button-bg--hover: rgba($primary-button-bg, 0.75);
$primary-button-border--hover: rgba($primary-button-border, 0.75);
$primary-button-bg--disabled: $gray-3;
$primary-button-border--disabled: $gray-3;
$primary-button-color--disabled: $white;

$secondary-button-bg: $white;
$secondary-button-border: $primary-color;
$secondary-button-bg--hover: $white;
$secondary-button-border--hover: rgba($primary-button-border, 0.75);
$secondary-button-bg--disabled: $white;
$secondary-button-border--disabled: $gray-5;
$secondary-button-color--disabled: $gray-5;

// Other Colors
$text-color: $gray-1;
$box-shadow: 0px $dp * 0.4 $dp $dp * -0.5 rgba($black, 0.25);

// =============================================================================
// Font, line-height, and color for body text, headings, and more.
// =============================================================================k
$regular: 'Golos Regular', 'Helvetica Neue', Arial, sans-serif !default;
$medium: 'Golos Medium', 'Helvetica Neue', Arial, sans-serif !default;
$semi-bold: 'Golos Semibold', 'Helvetica Neue', Arial, sans-serif !default;
$bold: 'Golos Bold', 'Helvetica Neue', Arial, sans-serif !default;

$h1-font-size-big: 2.5rem !default; //40px
$h1-font-size: 2.1875rem !default; //35px
$h1-font-size-small: 1.875rem !default; //30px
$h2-font-size: 1.68rem !default; //27px
$h2-font-size-t: 1.625rem !default; //27px
$h3-font-size: 1.5rem !default; //24px
$h4-font-size: 1.38rem !default; //22px
$h5-font-size: 1.25rem !default; //20px
$h6-font-size-m: 1.125rem !default; // 18px
$h6-font-size: 1rem !default; // 16px
$fz-base: 1rem !default;
