.verify-content {
	position: relative;

	&__container {
		width: 100%;
		display: flex;
		flex-direction: column;
		max-width: 862px;
		margin: 0 auto;
		padding: 0 1rem;
	}
	
	&__title {
		color: $black;
		font-family: $semi-bold;
		margin: 0;
		line-height: normal;
	}

	&__subtitle {
		text-align: center;
		font-family: $regular;
		font-size: $h4-font-size;
		margin-bottom: 2rem;
	}


	&__cards {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		&--content {
			display: flex;
		}
		&--message {
			padding: 0 0;
			margin: 0 0;
		}
		&--detail {
			width: 100%;
			&-info {
				margin-bottom: 1rem;
			}
			&-txt {
				font-family: $regular;
				color: $black;
				margin: 0;
				line-height: normal;
			}
		}
	}

	&__cardInfoWithoutSelection {
		background-image: url('../../assets/images/global/back-ground-identity.png');
		background-color: transparent;
		position: relative;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		display: flex;
		align-items: center;
		justify-content: center;
		.info-image {
			display: initial;
			width: 100%;
			max-width: 200px;
			box-shadow: 0px 3.28px 5.9px 0px #00000040;
		}
	}

	&__message {
		background-color: rgba(0, 80, 236, 0.06);
		border-radius: 0.586rem;
		width: 100%;
		border: 0.94px dashed $primary-color;

		&-detail {
			display: flex;
			&-info {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				gap: 12px;
				width: 100%;
				&-icon {
					font-size: 1.8rem;
					color: $blue;
				}
			}
		}

		.card-title,
		.card-text {
			margin: 0;
			padding: 0;
			line-height: normal;
		}
		.card-title {
			font-family: $medium;			
			color: $black;
		}
		.card-text {
			font-size: 0.875rem;
			font-family: $regular;
			color: #161723;
		}

		.card-icon__first,
		.card-icon__second {
			font-size: 1.8rem;
		}
		.card-icon__first {
			color: $blue;
		}
		.card-icon__second {
			color: $green;
		}
	}

	@media only screen and (min-width: 1025px) {
		padding: 5rem 0 10rem;
		&__container {
			padding: 0 1rem;
		}
		&__title {
			font-size: $h1-font-size-big;
			text-align: center;
		}
		&__cards {
			margin: 3rem 0 0 0;
			gap: 2rem;
			&--content {
				flex-direction: row;
				justify-content: space-between;
				gap: 2rem;
			}
			&--detail {
				max-width: 290px;
				&-txt {
					font-size: $h5-font-size;
				}
			}
		}
		&__message {
			padding: 1.063rem 2rem;
			.card-title {
				font-size: 1.171rem;
				text-align: center;
			}
			&-detail {
				margin-top: 16px;
				gap: 18px;
			}
		}
		&__cardInfoWithoutSelection {			
			padding: 2.5rem 0;
		}
	}
	@media (max-width: 1024px) {	
		margin: 2rem 0;
		&__container {
			padding: 0 1.5rem 10rem;
		}
		&__title {
			font-size: $h1-font-size-small;
			text-align: left;
		}
		&__cards {
			margin: 1rem 0 0 0;
			gap: 1rem;
			&--content {
				flex-direction: column;
				gap: 1rem;
			}
			&--detail {
				&-txt {
					font-size: $h6-font-size-m;
				}
			}
		}
		&__message {
			padding: 1.125rem;
			.card-title {
				font-size: 1rem;
				text-align: left;
			}
			&-detail {
				margin-top: 14px;
				gap: 14px;
				flex-direction: column;
			}
		}
		&__cardInfoWithoutSelection {			
			padding: 1rem 0;
		}
	}
}
