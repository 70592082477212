.container-carousel {
  width: 100%;
  overflow: hidden;
  position: sticky;
}

.cards {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-carousel-wrapper {
  display: flex;
  transition: transform 0.5s ease; 
}

.card-carousel {
  flex: 0 0 33.14%;
  opacity: 0.3;
  transition: transform 0.5s ease, opacity 0.5s ease;
} 

.selected {
  transform: scale(1);
  opacity: 1;
  filter: drop-shadow(0 0.3rem 0.3rem gray);
}

.card-carousel img {
  width: 100%;
  min-width: 114px;
  border-radius: 10px;
  object-fit: cover;
}

.prev-button,
.next-button {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  width: 44px;
  height: 44px;
  background-color: $primary-color;
  cursor: pointer;
  z-index: 1;
  border: none;
}

.prev-button:disabled,
.next-button:disabled {
  position: absolute;
  top: 45%;
  width: 44px;
  height: 44px;
  background-color: rgba(104, 106, 110, 0.9);
  cursor: no-drop;
  z-index: 1;
  border: none;
}

.prev-button {
  left: 0;
}

.next-button {
  right: 0;
}

.prev-button::before,
.next-button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-63%, -61%);
  border: solid white;
  border-width: 0 2px 2px 0;
  padding: 5px;
  cursor: pointer;
}

.prev-button {
  transform: rotate(135deg);
  border-radius: 50%;
}

.next-button {
  transform: rotate(-45deg);
  border-radius: 50%;
}

@media only screen and (max-width: 767px) {
  .container-carousel {
    width: 100%;
  }
  .prev-button,
  .next-button {
    width: 30px;
    height: 30px;
  }

  .prev-button:disabled,
  .next-button:disabled {
    width: 30px;
    height: 30px;
  }
}
