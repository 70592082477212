.download-content {
    max-width: 1123px;
    margin: auto;

    &__title {
		color: $black;
		font-family: $semi-bold;
		text-align: center;
		margin: 1.375rem 0 0;
	}
	
	&__message {
		color: $black;
		font-family: $regular;
		font-size: $h6-font-size-m;
		text-align: center;
		margin: 0 0 1rem 0;
	}

	&__text {
		margin-top: 2rem;
	}

	&__icon {
		width: 60px;
		height: 60px;
	}

	@media only screen and (min-width: 1025px) {
		padding: 5rem 1rem 11rem;
		&__title {
			font-size: $h1-font-size-big;
		}
		&__text {
			margin-top: 2rem;
		}
	}

	@media (max-width: 1024px) {	
		padding: 3rem 1.5rem 11rem;
		&__title {
			font-size: 1.7rem;
		}
		&__text {
			margin-top: 1.375rem;
		}
	}
}