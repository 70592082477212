.terms-content {
	position: relative;
  padding: 3rem 1.5rem 10rem;

  &__title {
    color: $black;
    font-family: $semi-bold;
    font-size: $h1-font-size-big;
    text-align: center;
    margin: 0 0 1.5rem 0;
  }

  &__subtitle {
    text-align: center;
    font-family: $regular;
    font-size: $h5-font-size;
    font-weight: normal;
  }
  &__agreements-box {
    max-width: 586px;
    width: 100%;
    margin: 1.875rem auto 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__form {
    display: flex;
    &-item {
      display: block;
    }
  } 
  @media (max-width: 1024px) {	
    &__title {
      font-size: $h1-font-size-small;
      text-align: left;
      margin-bottom: 18px;
      line-height: normal;
    }
    &__subtitle {
      font-size: $h5-font-size;
      text-align: left;
      line-height: normal;
    }
    &__form-item {
      margin-right: auto;
    }
  }

}

.password-requirements {
  &__label {
    color: $primary-color;
    font-family: $regular;
    font-weight: 400;
    font-size: 13px;
  }
}

.modals {
  p-dialog {
    .modals-content {
      overflow-y: auto;
      border: 1px solid $black;
      max-height: 75vh;
      padding-bottom: 4rem;
      @media only screen and (max-width: 767px) {
        border: 0;
      }
    }
    .p-dialog {
      .container {
        border: 0;
      }
      h3 {
        margin: 1rem;
        @media only screen and (max-width: 767px) {
          margin: 1rem 0;
        }
      }
      p {
        text-align: left;
        font-weight: normal;
        font-family: $regular;
        font-size: 1.1rem;
        padding: 1rem;

        @media only screen and (max-width: 767px) {
          font-size: 0.9rem;
          padding: 0.5rem;
        }
      }
      .p-dialog-header {
        display: flex;
        flex-direction: row-reverse;
      }
      .p-dialog-title {
        display: none;
      }
      max-height: 100%;
      &-content {
        overflow-y: hidden;
      }
    }
  }
}
