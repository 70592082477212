.integration-sdk__content {
	background-color: $white;
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;

	.content-iframe {
		height: 100vh;
		display: flex;
		justify-content: center;
		margin-top: 10px;

		@media (max-width: 768px) {
			margin-top: 0;
			height: 90vh;
			min-height: 600px;
		}

		:ng-deep {
			#onfido-sdk {
				._actions_1iu96_71 {
					margin: 4em auto;
				}
				._shutter-button-container_qhiks_93 {
					margin-bottom: 50%;
				}
			}
		}
	}
}