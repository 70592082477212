.disabled_label {
	opacity: 0.4;
	cursor: no-drop;
    color: $blue-disabled;
}

.line-separate {
	opacity: 0.4;
}


.subtitle{
	font-family: $regular;
	font-weight: normal;
}

.button-add {
    display: flex;
    align-items: center;

    &__button {
        width: 30px;
        height: 30px;
        border: 2px solid $primary-color;
        border-radius: $default-radius;
        display: flex;
        align-items: center;
        justify-content: center;

    &--disabled {
        border-color: $gray-3;
    }
}

&__label {
    color: $primary-color;
    font-family: $semi-bold;
    font-weight: 700;
    margin-left: 12px;
    font-size: 14px;

    &--disabled {
        color: $blue-disabled;
    }
    }
}



.p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0.375rem 0.75rem;
    margin-right: 0.5rem;
    background: $primary-color;
    color: $white;
    border-radius: 16px;
}

:host ::ng-deep .p-multiselect {
    // Estilo específico para cuando el multiselect está cerrado
    .p-multiselect {
        min-height: 46px; // Ajusta este valor para que coincida con el input superior
        
        &.p-multiselect-empty .p-multiselect-label {
        height: 100%;
        min-height: 46px; // Mismo valor que arriba
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        display: flex;
        align-items: center;
        }
        
        .p-multiselect-trigger {
        height: 46px; // Mismo valor que arriba
        display: flex;
        align-items: center;
        }
    }
    
    // Input regular para comparación (si necesitas ajustar también el input superior)
    input.p-inputtext,
    .p-inputnumber .p-inputtext {
        height: 46px; // Mismo valor que arriba
        padding: 0.5rem 0.75rem;
    }
}

.vertical-chips-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
    padding: 0 0;
}
  
.vertical-chip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $primary-color;
    color: $white;
    padding: 0.375rem 0.75rem;
    border-radius: 16px;
    width: fit-content;
    font-family: $regular;
    box-sizing: border-box;
    max-width: 135px;
    min-width: 135px;
    
    .pi-times-circle {
      cursor: pointer;
      margin-left: 8px;
    }
}

// Estilo personalizado para el botón de PrimeNG
:host ::ng-deep {
  // Esta es la clase específica que identificaste
  .p-button.p-button-icon-only {
    width: 30px;
    height: 30px;
    padding: 0.75rem 0;
    border: 2px solid $primary-color;

    &:disabled {
      border: 2px solid $gray-3;
    }
  }
  
  // También mantenemos los estilos para p-button-outlined por si acaso
  .p-button.p-button-outlined {
    width: 30px !important;
    height: 30px !important;
    padding: 0;
    border: 2px solid $primary-color !important;
    
    &:disabled {
      border: 2px solid $gray-3 !important;
    }
  }
}