.debit-content {
	position: relative;

	&__container {
		width: 100%;
		display: flex;
		flex-direction: column;
		max-width: 998px;
		margin: 0 auto;
	}

	&__col {
		width: 100%;
		&-one {
			max-width: 412px;
			margin-left: auto;
    		margin-right: auto;
		}
		&-two {
			max-width: 445px;
			margin-left: auto;
    		margin-right: auto;
		}
	}

	&__title, &__subtitle {
		margin: 0;
		line-height: normal;
	}

	&__title {
		font-size: 1.75rem;
		font-family: $semi-bold;
		margin: 0 0 20px;
		color: #1E1E1E;
	}

	&__subtitle {
		font-family: $regular;
		color: $black;
		font-size: $h5-font-size;
	}

	&__carousel {
		width: 100%;
		max-width: 320px;
		margin: 15px auto 0;
		vass-carousel {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&__card {
		border-radius: 16px;
		padding: 24px 48px;
		border: 1px solid #E4E4E4;

		.card-title {
			color: $black;
			margin-bottom: 20px;
			font-family: $semi-bold;
			font-size: 1.313rem;
		}

		.card-text {
			margin-bottom: 20px;
			font-family: $regular;
			font-size: 1rem;
		}

		.card-content {
			background-color: $blue;
			border-radius: 12px;
			color: $white;
			padding: 20px;

			.content-data {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-bottom: 20px;
			}
			.data-title, .data-value {
				margin: 0;
			}
			.data-icon {
				margin-right: 20px;
				width: 32px;
				height: 32px;
			}
			.data {
				&-title {
					font-family: $medium;
					font-size: $fz-base;
					line-height: normal;
					margin-bottom: 9px;
				}
				&-value {
					font-size: $h6-font-size-m;
					font-family: $regular;
					line-height: normal;
				}
			}

			.content-total { 
				p.txt {
					font-family: $semi-bold; 
					font-size: $h6-font-size-m;
					span.totalPrice {
						margin-left: 17px;
					}
				}
			}

			.content-message {
				margin-top: 16px;
				margin-bottom: 0;
				font-size: $fz-base;
				font-family: $regular;

				span {
					font-family: $semi-bold;
				}
			}
		}
	}

	&__message {
		background-color: rgba(0, 80, 236, 0.06);
		border-radius: 0.586rem;
		width: 100%;
		border: 0.94px dashed $primary-color;
		max-width: 962px; 
		margin: 30px auto 0;

		&-detail {
			display: flex;
      		margin: 0;
			&-info {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				width: 100%;
			}
		}
		&-icon {
			width: 23px;
			height: 23px;
		}
		&-text {
			color: #161723;
			margin: 0;
			padding: 0;
			line-height: normal;
			font-size: 0.875rem;
			font-family: $regular;
		}
	}

	@media only screen and (min-width: 1025px) {
		margin: 3rem 0;
		padding: 0 1.5rem 10rem;
		&__title, &__subtitle {
			text-align: center;
		}
	
		&__grid {
			display: grid;
			grid-template-areas: ". .";
			justify-items: stretch;
			justify-content: space-between;
			gap: 1rem;
		}
		&__message {
			padding: 1.063rem 2rem;
			&-detail {
				&-info {
					flex-direction: row;
					gap: 0.75rem;
				}
			}
		}
	}

	@media (max-width: 1024px) {	
		margin: 2rem 0;
		padding: 0 1.5rem 10rem;
		&__card {
			padding: 1rem;

			.card-title {
				line-height: normal;
			}
			.card-title,
			.card-text {
				margin-bottom: 1rem;
			}
		}
		&__subtitle {
			margin-bottom: .6rem;
		}
		.card-message {
			margin: 1.6rem;
			flex-direction: column;

			img {
				width: 5.5rem;
				margin: 0 auto 1rem;
			}
		}
		&__message {
			padding: 1.125rem;
			.card-title {
				font-size: 1rem;
				text-align: left;
			}
			&-detail {
				&-info {
					flex-direction: column;
					gap: 0.625rem;
				}
			}
		}
	}
}

