.loading-content {

	&__container {
		width: 100%;
		display: flex;
		flex-direction: column;
		max-width: 862px;
		margin: 0 auto;
		padding: 0 1rem;
	}
	
	&__title {
		color: $black;
		font-family: $semi-bold;
		margin: 0;
		line-height: normal;
		span {
			font-family: $bold;
		}
	}

	&__subtitle {
		font-family: $regular;
		margin: 0;
		color: #000;
		span {
			font-family: $bold;
		}
	}
	&__wrapper {
		display: flex;
		flex-direction: column;
	}

	&__form {
		margin: 0 auto;
		width: 100%;
		.formgrid {
			display: flex;
			flex-direction: column;
			gap: 1rem;
		}
		&-field {
			&-label {
				font-family: $regular;
				font-size: 1rem;
				color: $black;
				margin: 0;
			}
			.flex-column {
				border-bottom: 1px dashed #dfdfdf;
			}
			.contentDocument {
				display: grid;
				grid-auto-flow: column;
				grid-template-columns: auto 1fr;
				gap: 0;
				position: relative;
				
				&-detail {
					max-width: 18rem;		
					&_info {
						font-size: 0.875rem;
						font-family: $semi-bold;
						line-height: normal;	
						color: #161723;				
					}
					&_icon {				
						width: 1rem;
						height: 1rem;
						margin: 0;
						position: relative;
						left: 5px;
						top: 1px;
					}
				}
			}
			.files-input {
				font-size: $h6-font-size;
				font-family: $regular;
				color: $blue;
				line-height: normal;	
				&--line {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					text-decoration: dashed;
					line-height: 2rem;
					padding: 0.5rem 0;
				}
				&-icon-upload {
					margin: 0 0 0 0;
				}
			}
		}
	}
	&__message {
		background-color: rgba(0, 80, 236, 0.06);
		border-radius: 0.586rem;
		width: 100%;
		border: 0.94px dashed $primary-color;
		max-width: 779px;

		&-detail {
			display: flex;
      		margin: 0;
			&-info {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				width: 100%;
			}
		}
		&-icon {
		width: 23px;
		height: 23px;
		}
		&-text {
			color: #161723;
			margin: 0;
			padding: 0;
			line-height: normal;
			font-size: 0.875rem;
			font-family: $regular;
		}
	}

	.label-flex {
		width: 250px;

		img {
			width: 28px;
		}
	}

	.file-uploaded {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.5rem 0;
		margin: 0;
		flex-direction: row;
		-webkit-text-decoration: dashed;
		text-decoration: dashed;
		line-height: 2rem;

		&__text {
			display: flex;
			.text,
			.size {
				color: #028759;
				font-size: 1rem;
			}
			.text {
				border: 0;
				width: 100%;
				max-width: 198px;
				white-space: nowrap;
				overflow: hidden;
			}
			.size {
				border: 0;
			}
		}
		&__actions {
			display: flex;
			flex-direction: row;

			.actions-check {
				width: 20px;
			}

			.actions-delete {
				cursor: pointer;
				background-color: transparent;
				border: 0;
				margin: 0 0 0 11px;
				padding: 0;
			}
		}
	}
	@media only screen and (min-width: 1025px) {
		padding: 3rem 0 10rem;
		&__container {
			padding: 0 1rem;
		}
		&__title {
			font-size: $h1-font-size-big;
			text-align: center;
			margin-bottom: 1.875rem;
		}
		&__subtitle {
			font-size: $h5-font-size;
			text-align: center;
		}
		&__wrapper {
			margin: 3.125rem 0 0;
			gap: 3.125rem;
		}
		&__form {
			max-width: 340px;
			&-field {
				.files-input {	
					&-icon-upload {
						img {
							width: 1.5rem;
							height: 1.5rem;
						}
					}
				}
			}
		}
		&__message {
			padding: 1.063rem 2rem;
      		margin: 0 auto;
			&-detail {
				&-info {
					flex-direction: row;
					gap: 0.75rem;
				}
			}
		}
		&__cardInfoWithoutSelection {			
			padding: 2.5rem 0;
		}

		.file-uploaded {
			&__text {
				.text {
					max-width: 198px;
				}
			}
		}
	}
	@media (max-width: 1024px) {	
		margin: 2rem 0;
		&__container {
			padding: 0 1.5rem 10rem;
		}
		&__title {
			font-size: $h1-font-size-small;
			text-align: left;
			margin-bottom: 0.75rem;
		}
		&__subtitle {
			font-size: $h5-font-size;
			text-align: left;
		}
		&__wrapper {
			margin: 2.5rem 0 0;
			gap: 2.5rem;
		}
		&__form {
			max-width: none;
			padding: 0 14px;
			width: 100%;
			max-width: 330px;
			margin-left: auto;
			margin-right: auto;
			&-field {
				.files-input {	
					&-icon-upload {
						img {
							width: 1.25rem;
							height: 1.25rem;
						}
					}
				}
			}
		}
		.file-uploaded {
			&__text {
				.text {
					max-width: 130px;
				}
			}
		}
		&__message {
			padding: 1.125rem;
			.card-title {
				font-size: 1rem;
				text-align: left;
			}
			&-detail {
				&-info {
					flex-direction: column;
					gap: 0.625rem;
				}
			}
		}
		&__cardInfoWithoutSelection {			
			padding: 1rem 0;
		}
	}
}

.income-certificate {
	width: 100%;
}
