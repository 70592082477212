.breadcrumb-content {

	&__container {
		width: 100%;
		display: flex;
		flex-direction: column;
		max-width: 716px;
		margin: 0 auto;
		padding: 0 1.375rem;
	}

	&__back {
		&--intro {
			margin: 0;
			padding: 0;
		}
		&--title {
			color: $gray-6;
			font-family: $semi-bold;
			font-size: 1rem;
			padding: 0;
			margin: 0;
		}
	
	}

	&__step {
		padding: 0;
		display: flex;
		gap: 0.375rem;
		span {
		}
		&-item {
			margin: 0;
			flex-grow: 1;
			width: 100%;
			height: 100%;
			max-width: 6rem;
			height: 0.25rem;
			&:first-child {
				border-radius: 3.75rem 0 0 3.75rem;
			}
			&:last-child {
				border-radius: 0 3.75rem 3.75rem 0;
			}
		}
	}
	@media only screen and (min-width: 1025px) {
		margin-top: 3.375rem;
		&__container {
			gap: 0.75rem;
		}
	}
	@media (max-width: 1024px) {	
		margin: 0;
		&__container {
			gap: 0.75rem;
		}
	}
}