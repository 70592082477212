.container-global {
	position: relative;
    padding: 0 1.5rem 10rem;

	&__container {
		width: 100%;
		display: flex;
		flex-direction: column;
		max-width: 990px;
		margin: 0 auto;
	}
	
	&__title {
		color: $black;
		font-family: $semi-bold;
		margin: 0;
		line-height: normal;
	}
    
    &__separator {
        font-size: $h5-font-size;
        line-height: normal;
    }

    &__delivery {
        &-option {
            width: 100%;
            max-width: 835px;
            .received-options {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        &-map,
        &-form {
            width: 100%;
            max-width: 922px;
            margin: 0 auto;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        &-map {
            max-width: 662px;
        }
    }

    #map {
        width: 100%;
        max-width: 542px;
        border-radius: 12px;
        position: sticky;
        height: auto;
        &.leaflet-container {
            height: 321px;
        }
    }
    .map-wrapper {
        margin: 0;
        &__flex {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
        &__cat-map-wrapper {
            
        }
        .p-dropdown-clearable {
            width: 100%;
            height: 60px;
            display: flex;
            align-items: center;
            border: 1px solid #6D6D82;
        }
    }
	@media only screen and (min-width: 1025px) {
		margin: 3rem 0;
		&__title {
			font-size: $h1-font-size-big;
			text-align: center;
		}
        &__delivery-option {
            margin: 3rem auto;
            .received-options {
                gap: 1.875rem;
            }
        }
        #map {
            margin: 40px auto 0;
        }
	}
	@media (max-width: 1024px) {	
		margin: 2rem 0;
		&__title {
			font-size: $h1-font-size-small;
			text-align: left;
		}
        &__delivery-option {
            margin: 2rem 0;
            .received-options {
                flex-direction: column;
                gap: 0.563rem;
            }
        }
        #map {
            margin: 20px auto 0;
        }
        &__delivery {
            &-map,
            &-form {
                margin: 0;
                padding: 0;
            }
        }
        &__separator {
            display: none;
        }
	}
}

.card-message {
	& strong {
		display: contents; /* Color azul destacado */
	}
}



.title_step2 {
	margin: 4rem 14rem 1.87rem 14rem;
	font-family: "Golos Semibold", "Helvetica Neue", Arial, sans-serif;
	font-size: 2.1875rem;
	color: initial;
	line-height: initial;
	text-align: center;

	@media only screen and (max-width: 767px) {
		margin: 2rem 0;
		text-align: left;
		line-height: normal;
        font-size: 1.3rem;
	}
}

.subtitle-debit-card {
	margin: 0 12rem;
	text-align: center;
	@media only screen and (max-width: 767px) {
		text-align: left;
		margin: 0 1rem;
	}
}

.mrl-card-personalized {
	margin-left: 20px;
}

@media only screen and (max-width: 767px) {
	.subtitle-debit-card {
		margin: 0;
	}
	.mrl-card-personalized {
		margin-left: 0;
	}
}

.card-message {
	@media only screen and (max-width: 767px) {
		margin: 1rem;
	}
}
