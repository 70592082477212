.job-content{
	position: relative;

	&__container {
		width: 100%;
		display: flex;
		flex-direction: column;
		max-width: 862px;
		margin: 0 auto;
		&-form {
			width: 100%;
			max-width: 920px;
			margin: 0 auto;
		}
		&-activities {
			width: 100%;
			max-width: 997px;
			margin: 3.75rem auto 0;
			padding: 0 1.5rem 0;
		}
	}
	
	&__title {
		color: $black;
		font-family: $semi-bold;
		margin: 0;
		line-height: normal;
	}

    &__subtitle {
        font-family: $regular;
		font-weight: normal;
    }
	
	&__wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		&--content {
			display: flex;
		}
		&--message {
			padding: 0 0;
			margin: 0 0;
		}
		&--detail {
			width: 100%;
			&-info {
				margin-bottom: 1rem;
			}
			&-txt {
				font-family: $regular;
				color: $black;
				margin: 0;
				line-height: normal;
			}
		}
	}

	&__form {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		&--deleteItem {
			margin: 0;
			display: flex;
			justify-content: flex-end;
			position: absolute;
			right: -16px;
			top: 11px;
			border: 1px solid black;
			padding: 4px;
			background: white;
			width: fit-content;
			border-radius: 50%;
		}
		&-one {
			margin: 24px 0 0;
		}
		&-two {
			margin: 24px 0 0;
			display: flex;
			flex-direction: column;
			gap: 24px;
		}
		&-subtitle {
			font-family: $medium;
			margin: 0;
			font-size: $h5-font-size;
			display: inline-block;
		}
		&--field {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			width: 100%;
			max-width: 428px;
			position: relative;
			.flex {
				gap: 0.375rem;
			}
			&-label {
				font-family: $medium;
				color: #161723;
				font-size: 0.875rem;
				margin: 0;
			}
			.p-calendar .p-inputtext,
			.p-dropdown,
			ngx-intl-tel-input input,
			.p-inputnumber input,
			.p-calendar input,
			.input-calendar input,
			.iti input,
			&-input {
				background-color: transparent;
				border: 1px solid #6D6D82;
				font-family: $regular;
				color: #161723;
				font-size: $h6-font-size;
				margin: 0;
				height: 60px;
				display: flex;
				align-items: center;
				border-radius: 8px;
			}
			.input-calendar input,
			.p-calendar input {
				border-radius: 8px 0 0 8px !important;
			}
			input:disabled,
			.p-calendar.p-disabled .p-inputtext,
			.p-dropdown.p-disabled,
			.p-dropdown.p-disabled .p-dropdown-label,
			ngx-intl-tel-input.disabled input,
			.p-calendar.p-disabled input,
			.input-calendar.p-disabled input,
			.iti--disabled input,
			.iti.disabled input {
				background-color: #F2F2F2 !important;
				cursor: not-allowed !important;
			}
			.iti .dropdown-menu {
				min-width: 320px;
				z-index: 1;
			}
		}
		&-wrapp {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
		}
	}

	.wrapp {
		&-with-tooltip {
			position: relative;
			line-height: normal;
			.content-tooltip_img {
				width: 16px;
				height: 16px;
			}
		}
		&-icon-tooltip {
			display: inline;
			right: -6px;
			top: 3px;
			cursor: pointer;
			position: relative;
		}
	}

	&__pi-button {
		margin: 1rem 0 0;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 14px;
		&.flex-left {
			justify-content: flex-start;
			margin-top: 1.5rem;
		}
		.p-button {
			&.p-button-icon-only {
				width: 30px;
				height: 30px;
				border: 2px solid #0050EC;
				color: #0050EC;
				&.p-disabled {
					border: 2px solid $blue-disabled;
					color: $blue-disabled;
				}
			}
		}
		.button-add {
			&__label {
				color: $primary-color;
				font-family: $semi-bold;
				font-size: 0.875rem;
				margin: 0;
				&--disabled {
					color: $blue-disabled;
				}
			}
			&-mt {
				margin-top: 25px;
			}
		}
	}
	
	&__message {
		background-color: rgba(0, 80, 236, 0.06);
		border-radius: 0.586rem;
		width: 100%;
		border: 0.94px dashed $primary-color;
		max-width: 779px;

		&-detail {
			display: flex;
		margin: 0;
			&-info {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				width: 100%;
			}
		}
	&-icon {
		width: 23px;
		height: 23px;
	}
		&-text {
			color: #161723;
			margin: 0;
			padding: 0;
			line-height: normal;
			font-size: 0.875rem;
			font-family: $regular;
		}
	}

	// Input styles

    &__col {
        width: 100%;

        &.col {
            &-one {
                width: 100%;
                max-width: 443px;
                .col-one__title {
                    font-family: $medium;
                    color: #161723;
                    font-size: 0.875rem;
                }
            }
            &-two {
                width: 100%;
                max-width: 443px;
            }
        }
		.contentWithToltip,
		.contentWithToltipLess {
			position: relative;
			.twbk-tooltip {
				margin: 0 0 0 8px;
			}
		}
        .label-input {
            font-family: $medium;
            color: #161723;
            margin: 0 0 1rem 0;
            font-size: 0.875rem;
        }
		.line-separate {
			border: 1px solid #CBCBCB;
			margin: 0;
			padding: 0;
			opacity: 1;
			max-width: 292px;
		}
		.p-accordion-content {
			.label-input {
				margin: 0;
			}
			input,
			.p-calendar .p-inputtext,
			.p-dropdown,
			ngx-intl-tel-input input,
			.p-inputnumber input,
			.p-calendar input,
			.input-calendar input,
			.iti input,
			&-input {
				background-color: transparent;
				border: 1px solid #6D6D82;
				font-family: $regular;
				color: #161723;
				font-size: $h6-font-size;
				margin: 0;
				height: 60px;
				display: flex;
				align-items: center;
				border-radius: 8px;
			}
		}
    }
	&__wallet {
		&-options {
		    margin: 14px 0 24px;
			display: flex;
			gap: 30px;
			align-items: center;
		}
		&-option {
			display: flex;
			gap: 11px;
			align-items: center;
			&-txt {
				font-size: $fz-base;
				font-family: $regular;
				color: $black;
			}
			.p-radiobutton .p-radiobutton-box {
				border-color: $black;
				width: 25px;
				height: 25px;
				min-width: 25px;
				&.p-highlight {
					border-color: $black;
					background: $white;
					.p-radiobutton-icon {
						background-color: $primary-color;
						width: 17px;
    					height: 17px;
					}
				}
			}
		}
	}

	@media only screen and (min-width: 1025px) {
		margin: 3rem 0;
		&__container {
			padding: 0 1rem;
			&-wallet {
		        max-width: 922px;
				padding: 3.75rem 1.5rem 10rem;
				margin: 0 auto 0;
			}
			&-form {
				padding: 0 1rem 10rem;
			}
		}
		&__grid {
			display: grid;
			grid-template-areas: ". .";
			grid-template-columns: 48% 48%;
			gap: 2rem;
		}
        &__col {
            &.col {
                &-one {
                    .col-one__title {
                        margin: 0 0 25px 0;
                    }
                    .financial-content__fields {
                        gap: 21px;
                    }
                }
            }
        }
		&__form {
			gap: 1.5rem 2rem;
		}
		&__title {
			font-size: $h1-font-size-big;
			text-align: center;
		}
		&__subtitle {
			font-size: $h5-font-size;
			text-align: center;
			margin-top: 20px;
		}
		&__wrapper {
			margin: 3rem 0 0;
			gap: 3rem;
			&--content {
				flex-direction: row;
				justify-content: space-between;
				gap: 2rem;
			}
			&--detail {
				max-width: 290px;
				&-txt {
					font-size: $h5-font-size;
				}
			}
		}
		&__message {
			padding: 1.063rem 2rem;
			margin: 0 auto;
			&-detail {
				&-info {
					flex-direction: row;
					gap: 0.75rem;
				}
			}
		}
		&__cardInfoWithoutSelection {			
			padding: 2.5rem 0;
		}
	}
	@media (max-width: 1024px) {	
		margin: 2rem 0;
			&__container {
				padding: 0 1.5rem 0;  
				max-width: 648px;
				margin-left: auto;
				margin-right: auto;
				&-form {
					padding: 0 1.5rem 10rem;
				}
				&-wallet {
					padding: 1.5rem 1.5rem 10rem;
				}
		}
		&__form {
			gap: 1.5rem;
			justify-content: center;
		}
		.job-content__col {
			&.col-one {	
				margin-bottom: 30px;
			}
			.line-separate {
				display: none;
			}
		}
		&__title {
			font-size: $h1-font-size-small;
			text-align: left;
		}
		&__subtitle {
			font-size: $h6-font-size-m;
			margin-top: 18px;
		}
        &__pi-button {
     
        }
		&__wrapper {
			margin: 1rem 0;
			gap: 1rem;
			&--content {
				flex-direction: column;
				gap: 1rem;
			}
			&--detail {
				&-txt {
					font-size: $h6-font-size-m;
				}
			}
		}
		&__message {
			padding: 1.125rem;
			.card-title {
				font-size: 1rem;
				text-align: left;
			}
			&-detail {
				&-info {
					flex-direction: column;
					gap: 0.625rem;
				}
			}
		}
		&__cardInfoWithoutSelection {			
			padding: 1rem 0;
		}
	}
}
