.verification-content {
	position: relative;

	&__container {
		width: 100%;
		display: flex;
		flex-direction: column;
		max-width: 910px;
		margin: 0 auto;
		&-form {
			width: 100%;
			max-width: 920px;
			margin: 0 auto;
		}
	}
	
	&__title {
		color: $black;
		font-family: $semi-bold;
		margin: 0;
		line-height: normal;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		&--content {
			display: flex;
		}
		&--message {
			padding: 0 0;
			margin: 0 0;
		}
		&--detail {
			width: 100%;
			&-info {
				margin-bottom: 1rem;
			}
			&-txt {
				font-family: $regular;
				color: $black;
				margin: 0;
				line-height: normal;
			}
		}
	}

  &__form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    &--field {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
      max-width: 428px;
	  position: relative;
	.error_small-two {
		bottom: -27px;
	}
	.flex {
		gap: 0.375rem;
	}
      &-label {
        font-family: $medium;
        color: #161723;
        font-size: 0.875rem;
        margin: 0;
      }
      .p-calendar .p-inputtext,
      .p-dropdown,
      ngx-intl-tel-input input,
      .p-calendar input,
      .input-calendar input,
      .iti input,
      &-input {
        background-color: transparent;
        border: 1px solid #6D6D82;
        font-family: $regular;
        color: #161723;
        font-size: $h6-font-size;
        margin: 0;
        height: 60px;
        display: flex;
        align-items: center;
        border-radius: 8px;
      }
      .input-calendar input,
      .p-calendar input {
        border-radius: 8px 0 0 8px !important;
      }
      input:disabled,
      .p-calendar.p-disabled .p-inputtext,
      .p-dropdown.p-disabled,
      .p-dropdown.p-disabled .p-dropdown-label,
      ngx-intl-tel-input.disabled input,
      .p-calendar.p-disabled input,
      .input-calendar.p-disabled input,
      .iti--disabled input,
      .iti.disabled input {
        background-color: #F2F2F2 !important;
        cursor: not-allowed !important;
      }
      .iti .dropdown-menu {
        min-width: 320px;
      }
    }
  }

	&__message {
		background-color: rgba(0, 80, 236, 0.06);
		border-radius: 0.586rem;
		width: 100%;
		border: 0.94px dashed $primary-color;
		max-width: 779px;

		&-detail {
			display: flex;
      		margin: 0;
			&-info {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				width: 100%;
			}
		}
		&-icon {
			width: 23px;
			height: 23px;
		}
		&-text {
			color: #161723;
			margin: 0;
			padding: 0;
			line-height: normal;
			font-size: 0.875rem;
			font-family: $regular;
		}
	}

	@media only screen and (min-width: 1025px) {
		margin: 3rem 0;
		&__container {
      	padding: 0 1rem;
			&-form {
				padding: 0 1rem 10rem;
			}
		}
		&__form {
			gap: 1.5rem 2rem;
		}
		&__title {
			font-size: $h1-font-size-big;
			text-align: center;
		}
		&__wrapper {
			margin: 3rem 0;
			gap: 3rem;
			&--content {
				flex-direction: row;
				justify-content: space-between;
				gap: 2rem;
			}
			&--detail {
				max-width: 290px;
				&-txt {
					font-size: $h5-font-size;
				}
			}
		}
		&__message {
			padding: 1.063rem 2rem;
      		margin: 0 auto;
			&-detail {
				&-info {
					flex-direction: row;
					gap: 0.75rem;
				}
			}
		}
		&__cardInfoWithoutSelection {			
			padding: 2.5rem 0;
		}
	}
	@media (max-width: 1024px) {	
		margin: 2rem 0;
		&__container {
			padding: 0 1.5rem 0;
			max-width: 540px;
			margin-left: auto;
			margin-right: auto;
			&-form {
				padding: 0 1.5rem 10rem;
			}
		}
		&__form {
			gap: 1.5rem;
			justify-content: center;
		}
		&__title {
			font-size: $h1-font-size-small;
			text-align: left;
		}
		&__message {
			margin-left: auto;
			margin-right: auto;
		}
		&__wrapper {
			margin: 1rem 0;
			gap: 1rem;
			&--content {
				flex-direction: column;
				gap: 1rem;
			}
			&--detail {
				&-txt {
					font-size: $h6-font-size-m;
				}
			}
		}
		&__message {
			padding: 1.125rem;
			.card-title {
				font-size: 1rem;
				text-align: left;
			}
			&-detail {
				&-info {
					flex-direction: column;
					gap: 0.625rem;
				}
			}
		}
		&__cardInfoWithoutSelection {			
			padding: 1rem 0;
		}
	}
}

