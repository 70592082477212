.finished-content {
    max-width: 1123px;
    margin: auto;

	&__title {
		color: $black;
		font-family: $semi-bold;
		text-align: center;
		margin: 1.375rem 0 0;
	}

	&__message {
		color: $black;
		font-family: $regular;
		font-size: $h6-font-size-m;
		text-align: center;
		margin: 0 0 1rem 0;
	}

	&__text {
		margin-top: 2rem;
		&.text-email {
			max-width: 600px;
			padding: 0 1rem;
			text-align: center;
			margin-bottom: 50px;
		}
		span {
			font-family: $bold;
		}
	}

	&__icon {
		width: 60px;
		height: 60px;
	}

	&__card {
		border: 1px solid #B2B2B2;
		border-radius: 16px;
		display: flex;
		justify-content: center;
		width: 550px;
		padding: 27px 10px;
		margin-left: -20px;
		margin-top: 20px;
		margin-bottom: 20px;
		.card-divider-h {
			display: none;
		}
		@media only screen and (max-width: 767px) {
			width: 80%;
			flex-direction: column;
			text-align: left;
			font-size: 1.4rem;
			margin-left: 0;
			.card-divider-v {
				display: none;
			}
			.card-divider-h {
				display: block;
			}
		}

		.card-text {
			text-align: center;
			color: $black;
			&:last-child {
				padding-right: 25px;
				@media only screen and (max-width: 767px) {
					padding-right: 0;
				}
			}
		}
	}

	&__cardOne {
		border: 1px solid #B2B2B2;
		border-radius: 16px;
		display: flex;
		justify-content: center;
		width: 50%;
		padding: 27px 10px;
		margin-top: 40px;
		margin-bottom: 20px;
		@media only screen and (max-width: 767px) {
			width: 80%;
		}
		.card-text {
			text-align: center;
			color: $black;
		}
	}

	&__apps {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 40px;

		a {
			margin-right: 20px;
		}
	}

	@media only screen and (min-width: 1025px) {
		padding: 5rem 1rem 11rem;
		&__title {
			font-size: $h1-font-size-big;
		}
		&__text {
			margin-top: 2rem;
		}
	}

	@media (max-width: 1024px) {	
		padding: 3rem 1.5rem 11rem;
		&__title {
			font-size: 1.7rem;
		}
		&__text {
			margin-top: 1.375rem;
		}
	}
}
