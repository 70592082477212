.user-content {
    position: relative;
    padding: 3rem 1.5rem 10rem;

    &__title {
        color: $black;
		font-family: $semi-bold;
		margin: 0 0 0.938rem 0;
        line-height: normal;
    }

    &__subtitle {
        font-family: $regular;
        line-height: 1.5;
        color: $black;
        font-weight: normal;
    }
    &__credentials-form {
        max-width: 466px;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 22px;
    }

    &__secret-question {
        &-description {
            max-width: 575px;
            width: 100%;
            margin: 0 auto;
        }
        &-form {
            max-width: 466px;
            margin: 35px auto 0;
            display: flex;
            flex-direction: column;
            gap: 22px;
        }
    }

    p-divider.p-element {
        max-width: 1019px;
        width: 100%;
        margin: 0 auto;
        display: block;
        .p-divider.p-divider-horizontal {
            padding: 1.875rem 0;
            margin: 0;
        }
    }

    .p-password {
        width: 100%;

        .p-password-input {
            background-color: transparent;
            border: 1px solid #6D6D82;
            font-family: $regular;
            color: #161723;
            font-size: $h6-font-size;
            margin: 0;
            height: 60px;
            display: flex;
            align-items: center;
            border-radius: 8px;
        }
        .p-password-panel {
            display: none;
        }
    }

	@media only screen and (min-width: 1025px) {		
		&__title {
			font-size: $h1-font-size-big;
			text-align: center;
		}
		&__subtitle {
			font-size: $h5-font-size;
			text-align: center;
		}
        &__form {
            margin: 50px 0 0;
        }
	}
	@media (max-width: 1024px) {	
        &__intro {
            max-width: 572px;
            margin: auto;
        }		
		&__title {
			font-size: $h1-font-size-small;
			text-align: left;
		}
		&__subtitle {
			font-size: $h6-font-size-m;
		}
        &__form {
            margin: 30px 0 0;
        }
	}


}