// ============================================================================
//
// COMPONENTS - HEADER
//
// ============================================================================
.header {
	display: flex;
	align-items: center;
	@media only screen and (min-width: 1025px) {
		background-color: $white-1;	
		box-shadow: $box-shadow;
		box-shadow: 0px 1px 10px 0px #00000040;
		padding: 20px 65px;
		height: 60px;
		&-content__logo {
			&-ikigii {
		        max-width: 66px;
			}
			&-tower {
				max-width: 97px;
			}
		}
	}
	@media (max-width: 1024px) {		
		padding: 33px 65px;
		&-content__logo {
			&-ikigii {
				max-width: 93px;
			}
			&-tower {
				display: none;
			}
		}
	}

	&-content {
		max-width: 1310px;
		margin: 0 auto;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media only screen and (min-width: 1025px) {
			justify-content: space-between;
		}
		@media (max-width: 1024px) {
			justify-content: center;		
		}
		&__logo {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			gap: 8px;
			figure {
				margin: 0;
				padding: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				img {
					width: 100%;
					height: 100%;
				}
			}
		}
		&__close {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			height: 2rem;
	
			.p-button.p-button-danger.p-button-text {
				color: transparent;
				background-color: transparent;
				border: 1px solid transparent;
				border-radius: 0;
				&:enabled:focus {
					border: 1px solid transparent;
					box-shadow: none;
				}
			}

			&-icon {
				height: 19px;
				width: 19px;
			}
			
			@media (max-width: 1024px) {
				position: absolute;
				right: 20px;
				top: 20px;
				height: auto;
				width: auto;
	
				.p-button.p-button-danger.p-button-text {
					padding: 0;
					width: 24px;
					height: 24px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				&-icon {
					height: 12.7px;
					width: 12.7px;
				}
			}
		}
	}

}
