.p-button-success {
	width: 100%;
	max-width: 380px;
	height: 60px;
	border-radius: 35px;
	background-color: #FFF;
	border: 3px solid #1dc57d;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	gap: 12px;
	color: #1f2d5e;
	font-size: 0.938rem;
	img {
		width: 24px;
		height: 24px;
	}
	&:focus,
	&:active,
	&-active {
		background: #1dc57d;
		color: $black;
	}
	.font-bold {
		font-family: $medium;
	}
}
