.otp-content {
	&__title,
	&__text {
		color: $black;
	}
	&__title {
		margin: 118px auto 38px auto;
		font-family: $semi-bold;
		// max-width: 1109px;
	}

	&__inputs {
		margin: 36px auto;
		.ng-otp-input-wrapper {
		    gap: 9px;
			display: flex;
			flex-direction: row;
			> .otp-input {
				width: 100%;
				height: 100%;
				max-width: 3.5rem;
				max-height: 3.5rem;
				min-height: 3.5rem;
				border-radius: 8px;
				font-size: $h3-font-size;
				font-family: $semi-bold;
				color: $black;
				border: 1px solid $black;
				margin: 0 0 !important;
			}
		}
	}

	&__text {
		margin-bottom: 0;
		font-family: $regular;
		span {
			color: $blue;
			font-family: $semi-bold;
		}
	}
	&__cta {
	    margin: 49px auto 0;
		width: 100%;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
	}
	&--center {
		.link {
			padding: 0;
			border: 0;
			all: unset;
			display: block;
			margin: 14px auto 0;
			font-size: 1.125rem;
			font-family: $semi-bold;
			box-shadow: none;
			transition-duration: .3s;
			position: relative;
			width: fit-content;
			&:disabled {
				color: gray;
				border-bottom: 1px dotted gray;
			}
			&:not(:disabled) {
				cursor: pointer;		
				color: $blue;
				border-bottom: 1px dotted $blue;
				&:hover {
					transform: translate3d(0, -2px, 0);
					&:before {
						background-color: $blue;
						transform: scaleX(1) translateY(100%);
						transition: all .3s ease;
						z-index: 1;
					}
				}	
			}
			&:before {
				border-radius: 50px;
				content: "";
				position: absolute;
				bottom: 0;
				display: inline-block;
				height: 1.1px;
				left: 0;
				top: auto !important;
				transform: scaleX(0) translateY(100%);
				transition: all .3s ease;
				width: 100%;
			}		
		}
	}
	@media only screen and (min-width: 1025px) {
		padding: 0 0 10rem;
		&--center {
			align-items: center;
		}
		&__text {
			font-size: $h5-font-size;
		}
		&__title {
			text-align: center;
			font-size: $h1-font-size-big;
		}
	}
	@media (max-width: 1024px) {
		padding: 0 0 13rem;
		&__title {
			line-height: normal;
			margin: 0 0 18px 0;
			font-size: $h1-font-size-small;
			max-width: 857px;
			margin-right: auto;
			margin-left: auto;
		}
		&__text {
			font-size: $h6-font-size-m;
			&-smLeft {
				max-width: 857px;
				margin-right: auto;
				margin-left: auto;
				width: 100%;
			}
		}
	}
	@media (max-height: 620px) {
		
	}
}
